import React from 'react'

const RealtyContext = React.createContext(false)

export const RealtyProvider = ({ children, value }) => (
  <RealtyContext.Provider value={{ ...value }}>
    {children}
  </RealtyContext.Provider>
)

export const RealtyConsumer = RealtyContext.Consumer
