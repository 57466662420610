import React from 'react'
import classNames from 'classnames'
// import './index.css'

const LayersSvgIcon = ({ height, width, className }) => {
  const classes = classNames('LayersSvgIcon', {
    [className]: className,
  })

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      className={classes}
      viewBox="0 0 20 20"
    >
      <path
        fill="#797E85"
        fillRule="evenodd"
        d="M10.5 11.437L3.287 7.658l7.213-3.78 7.213 3.78-7.213 3.78zm7.213-.934L10.5 14.282l-7.213-3.78 1.835-.954 5.167 2.722c.063.04.127.04.211.04.084 0 .148-.02.21-.04l5.168-2.722 1.835.955zm0 2.844l-7.213 3.78-7.213-3.78 1.835-.955 5.167 2.723c.063.04.127.04.211.04.084 0 .148-.02.21-.04l5.168-2.723 1.835.955zM19 7.658a.45.45 0 0 0-.21-.366l-8.08-4.246a.462.462 0 0 0-.4 0l-8.1 4.246a.424.424 0 0 0-.21.366c0 .163.084.284.21.366L4.237 9.08 2.21 10.137a.424.424 0 0 0-.211.366c0 .162.084.284.21.365l2.026 1.057L2.21 12.98a.424.424 0 0 0-.211.366c0 .163.084.285.21.366l8.08 4.246c.062.041.126.041.21.041.084 0 .148-.02.21-.04l8.08-4.247a.424.424 0 0 0 .21-.366.424.424 0 0 0-.21-.366l-2.026-1.056 2.025-1.057a.424.424 0 0 0 .211-.365.424.424 0 0 0-.21-.366L16.763 9.08l2.025-1.056A.45.45 0 0 0 19 7.658z"
      />
    </svg>
  )
}

export default LayersSvgIcon
