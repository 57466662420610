import React from 'react'

const SearchContext = React.createContext(false)

export const SearchProvider = ({ children, value }) => (
  <SearchContext.Provider value={{ ...value }}>
    {children}
  </SearchContext.Provider>
)

export const SearchConsumer = SearchContext.Consumer
