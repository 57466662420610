import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { MAP, MAP_MOBILE } from 'constants/map'
import './index.css'

class ClusterMarker extends React.PureComponent {
  render() {
    const {
      pointsCount,
      currentZoom,
      zoomClick,
      zoomedClick,
      mobile,
    } = this.props
    const zoomed = mobile
      ? currentZoom >= MAP_MOBILE.options.maxZoom
      : currentZoom >= MAP.options.maxZoom
    const clusterClasses = classNames('ClusterMarker', {
      'ClusterMarker--zoomed': zoomed,
    })
    const textClasses = classNames('ClusterMarker__Text')
    return (
      <div
        className={clusterClasses}
        onClick={zoomed ? zoomedClick : zoomClick}
      >
        <div className={textClasses}>{pointsCount}</div>
      </div>
    )
  }
}

ClusterMarker.propTypes = {
  pointsCount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func,
  currentZoom: PropTypes.number,
}

ClusterMarker.defaultProps = {
  pointsCount: 2,
  onClick: null,
  currentZoom: -1,
}

export default ClusterMarker
