export const removeArrayDuplicates = all => {
  let seen = []
  let res = []
  for (const e in all) {
    const temp = all[e]
    if (seen.indexOf(temp.toString()) < 0) {
      seen.push(temp.toString())
      res.push(temp)
    }
  }
  return res
}
