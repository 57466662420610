import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import './index.css'

const Select = ({
  component,
  name,
  type,
  placeholder,
  label,
  error,
  touched,
  classes,
  children,
  ...props
}) => {
  const inputClasses = classNames({
    [classes.inputComponent]: classes.inputComponent,
    [classes.inputError]: error,
  })
  const feedBackClasses = classNames({
    Select__Feedback: !classes.inputFeedback,
    [classes.inputFeedback]: classes.inputFeedback,
  })
  const inputWrapperClasses = classNames({
    Select: !classes.inputWrapper,
    [classes.inputWrapper]: classes.inputWrapper,
  })
  const labelClasses = classNames({
    Select__Label: !classes.label,
    [classes.label]: classes.label,
  })
  return (
    <div className={inputWrapperClasses}>
      <label className={labelClasses} htmlFor={name}>
        {label}
      </label>
      <select
        component={component}
        type={type}
        name={name}
        placeholder={placeholder}
        className={inputClasses}
        {...props}
      >
        <option value="">Selecione uma opção</option>
        {children}
      </select>
      {error && <div className={feedBackClasses}>{error}</div>}
    </div>
  )
}

Select.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  label: PropTypes.string,
  error: PropTypes.string,
  touched: PropTypes.bool,
  classes: PropTypes.shape({
    inputComponent: PropTypes.string,
    inputError: PropTypes.string,
    inputFeedback: PropTypes.string,
    inputWrapper: PropTypes.string,
    label: PropTypes.string,
  }),
}

Select.defaultProps = {
  placeholder: 0,
  type: 'text',
  error: '',
  touched: false,
  classes: {
    inputWrapper: 'Select',
    inputComponent: 'Select__Input',
    inputFeedback: 'Select__Feedback',
    inputError: 'Select__Input--error',
    label: 'Select__Label',
  },
}

export default Select
