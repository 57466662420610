import React, { Component } from 'react'
import { Formik, Checkbox, Field } from 'components/Form'
import { Row, Col } from 'components/Grid'
import { Button } from 'components/Buttons'
import { userInfo } from 'services/auth'
import { reportBug } from 'services/user'
import classNames from 'classnames'
import './index.css'

class ReportModal extends Component {
  constructor(props, context) {
    super(props, context)
    this.state = {
      address: false,
      adExpired: false,
      other: false,
      otherText: '',
      submitted: false,
    }
  }

  goBack = () => {
    const { toggle } = this.props
    this.setState({
      address: false,
      adExpired: false,
      other: false,
      otherText: '',
      submitted: false,
    })
    toggle && toggle()
  }

  submitReport = (values, props) => {
    let errors = []
    Object.keys(values).forEach(key => {
      if (values[key]) {
        switch (key) {
          case 'address':
            errors.push('Imóvel com Endereço errado.')
            break
          case 'adExpired':
            errors.push('Anúncio não existe mais.')
            break
          case 'other':
            errors.push('Outros:')
            errors.push(values.otherText)
            break
          default:
            break
        }
      }
    })
    const { id } = this.props

    reportBug({
      id,
      error1: errors[0],
      error2: errors[1],
      error3: errors[2],
      error4: errors[3],
      email: userInfo().email,
    }).then(() => this.setState({ submitted: true }))
  }

  render() {
    const { visible, toggle } = this.props
    const classes = classNames({
      ReportModal: visible,
      'ReportModal--hidden': !visible,
    })
    const { address, adExpired, other, otherText, submitted } = this.state
    return (
      <div className={classes}>
        <div className={'ReportModal__Modal'}>
          <div className="ReportModal__Header">
            <i className="material-icons" onClick={toggle}>
              close
            </i>
          </div>
          <div className="ReportModal__Body container-fluid">
            <h2 className="ReportModal__Body__Header">
              {submitted ? 'Obrigado pelo feedback' : 'Reportar erro no imóvel'}
            </h2>
            {(!submitted && (
              <Formik
                initialValues={{ address, adExpired, other, otherText }}
                onSubmit={this.submitReport}
              >
                {({
                  handleSubmit,
                  handleChange,
                  setFieldValue,
                  values: { address, adExpired, other, otherText },
                }) => (
                  <form onSubmit={handleSubmit}>
                    <Row around="xs">
                      <Col xs={12} className="ReportModal__Col">
                        Por favor, escolha a opção que descreve o problema:
                      </Col>
                    </Row>
                    <Row around="xs">
                      <Col xs={12} className="ReportModal__Col">
                        <Checkbox
                          name="address"
                          label="Imóvel com Endereço errado."
                          checked={address}
                          onChange={handleChange}
                        />
                      </Col>
                      <Col xs={12} className="ReportModal__Col">
                        <Checkbox
                          name="adExpired"
                          label="Anúncio não existe mais."
                          checked={adExpired}
                          onChange={handleChange}
                        />
                      </Col>
                      <Col xs={12} className="ReportModal__Col">
                        <Checkbox
                          name="other"
                          label="Outro:"
                          checked={other}
                          onChange={handleChange}
                        />
                      </Col>

                      {other && (
                        <Col xs={12} className="ReportModal__Col">
                          <Field
                            placeholder="Informe o Motivo"
                            name="otherText"
                            value={otherText}
                            onChange={({ target: { name, value } }) =>
                              setFieldValue(name, value)
                            }
                          />
                        </Col>
                      )}
                    </Row>
                    <Row className="ReportModal__Footer">
                      <Col
                        xs={12}
                        className="ReportModal__Col ReportModal__Submit"
                      >
                        <Button type="submit" btnStyle="primary" btnSize="md">
                          Enviar
                        </Button>
                      </Col>
                    </Row>
                  </form>
                )}
              </Formik>
            )) || (
              <div className="ReportModal__GoBackButton">
                <Button btnStyle="primary" btnSize="md" onClick={this.goBack}>
                  Voltar
                </Button>
              </div>
            )}
          </div>
        </div>
      </div>
    )
  }
}

export default ReportModal
