import React, { useState } from 'react'
import classNames from 'classnames'
import { Grid, Row, Col } from 'components/Grid'
import Logo from 'components/Logo'
import NavMenu from 'components/NavMenu'
import UserMenu from 'components/UserMenu'
import { Formik, Form, InputMask } from 'components/Form'
import { updateUserInfo } from 'services/user'
import { Button } from 'components/Buttons'
import { userInfo, updateCredential } from 'services/auth'
import { callRequest } from 'services/requestHandler'
import { PHONE_MASK, TELEPHONE_VALIDATION } from 'utils/mask'
import * as Yup from 'yup'
import i18n from 'locales'
import './index.css'

const PhoneNumberSchema = Yup.object().shape({
  PhoneNumber: Yup.string()
    .matches(TELEPHONE_VALIDATION, 'WhatsApp Inválido')
    .required(i18n.t('login.required')),
})

const updatePhoneNumber = ({ PhoneNumber }, setLoading) => {
  const user = userInfo()
  const payload = {
    CidadeId: user.cityId,
    Endereco: user.address,
    EstadoId: user.ufId,
    GestorId: user.managerId,
    Id: user.userId,
    ImobiliariaId: user.realStateId,
    Nome: user.username,
    TipoPerfil: user.profileType,
    Telefone: PhoneNumber,
  }
  setLoading(true)
  callRequest(
    () => updateUserInfo(payload),
    () => {
      updateCredential({ phone: PhoneNumber })
      window.M.toast({
        html: i18n.t('common.successfullyAltered'),
        classes: 'Toast--success',
      })
      setLoading(false)
    },
    () => {
      window.M.toast({
        html: i18n.t('common.fail'),
        classes: 'Toast--failure',
      })
      setLoading(false)
    },
    () => setLoading(false),
    () => window.location.reload(),
  )
}

const HeaderLoggedIn = ({ home, transparent }) => {
  const [loading, setLoading] = useState(false)
  const classes = classNames('HeaderLoggedIn', 'container-fluid', {
    Header: !transparent,
    'Header--transparent': transparent,
    'Header--home': home,
  })

  return (
    <header className={classes}>
      <Grid>
        <Row>
          <Col xs={2}>
            <a href={i18n.t('routes.search')}>
              <Logo className="Header__Logo" title="EEmovel" />
            </a>
          </Col>
          <Col xs={10}>
            <div className="Header__Hide">
              <Row end="xs">
                <Col>
                  <NavMenu />
                </Col>
                <Col>
                  <UserMenu />
                </Col>
              </Row>
            </div>
          </Col>
        </Row>
      </Grid>
      {!userInfo().phone && userInfo().profileType === 4 && (
        <div className="Header__Pane">
          <div className="Header__Modal">
            <Row>
              <Col xs={12}>
                <h1 className="Header__ModalHeader">Complete seu cadastro!</h1>
              </Col>
            </Row>
            <Formik
              validationSchema={PhoneNumberSchema}
              initialValues={{ PhoneNumber: '' }}
              onSubmit={values => updatePhoneNumber(values, setLoading)}
            >
              {({
                handleSubmit,
                handleChange,
                values: { PhoneNumber },
                errors,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <Row className="Header__FormRow">
                    <Col xs={12}>
                      <InputMask
                        label="Insira o número do seu WhatsApp"
                        error={errors.PhoneNumber}
                        name="PhoneNumber"
                        placeholder="WhatsApp"
                        onChange={handleChange}
                        value={PhoneNumber}
                        mask={PHONE_MASK[0]}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <Button
                        disabled={loading}
                        btnStyle="primary"
                        block
                        type="submit"
                      >
                        {loading ? 'Carregando...' : i18n.t('common.save')}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </header>
  )
}

export default HeaderLoggedIn
