import React from 'react'
import classNames from 'classnames'
import { Button } from 'components/Buttons'
import Badge from 'components/Badge'
import ArrowSvgIcon from 'components/SvgIcons/ArrowSvgIcon'

export { Formik, Form, FastField } from 'formik'
export { Formover } from 'formover'
export { default as Field } from './Field'
export { default as Checkbox } from './Checkbox'
export { default as Select } from './Select'
export { default as InputMask } from './InputMask'
export { default as AutoComplete } from './AutoComplete'
export { default as CurrencyField } from './Currency'
export { default as SelectTypeahead } from './SelectTypeahead'
export { Slider, Range } from './Slider'

export const createFilterButton = ({
  label,
  className,
  count = 0,
  icon = {},
  error = false,
}) => {
  const Component = ({ getTargetProps, isOpen, ...rest }) => {
    const { innerRef, ...innerProps } = getTargetProps()
    const { className: iconClassName, size } = icon
    const classes = classNames('FilterButton__Btn', className, {
      'FilterButton__Btn--active': count > 0,
      'FilterButton__Btn--error': error,
    })
    const iconClasses = classNames('FilterButton__Btn__Icon', iconClassName)
    const badgeClasses = classNames('FilterButton__Btn__Badge', {
      'FilterButton__Btn__Badge--hidden': count === 0,
    })
    return (
      <div className="FilterButton">
        <Button
          ref={innerRef}
          className={classes}
          {...innerProps}
          btnStyle="link"
        >
          {label}
          <Badge
            anchor="inline"
            size={20}
            shadow={false}
            className={badgeClasses}
          >
            {count}
          </Badge>
          <ArrowSvgIcon
            up={isOpen}
            className={iconClasses}
            width={size}
            height={size}
          />
        </Button>
      </div>
    )
  }

  return Component
}

export const createFilter = ({
  label,
  className,
  count = 0,
  icon = {},
  error = false,
  isOpen,
  onClick,
  dataTut = null,
}) => {
  const { className: iconClassName, size } = icon
  const classes = classNames('FilterButton__Btn', className, {
    'FilterButton__Btn--active': count > 0,
    'FilterButton__Btn--error': error,
  })
  const iconClasses = classNames('FilterButton__Btn__Icon', iconClassName)
  const badgeClasses = classNames('FilterButton__Btn__Badge', {
    'FilterButton__Btn__Badge--hidden': count === 0,
  })
  return (
    <div className="FilterButton">
      <Button
        className={classes}
        btnStyle="link"
        onClick={onClick}
        data-tut={dataTut}
      >
        {label}
        <Badge
          anchor="inline"
          size={20}
          shadow={false}
          className={badgeClasses}
        >
          {count}
        </Badge>
        <ArrowSvgIcon
          up={isOpen}
          className={iconClasses}
          width={size}
          height={size}
        />
      </Button>
    </div>
  )
}
