import React from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import CurrencyFormat from 'react-currency-format'
import './index.css'

const CurrencyField = ({
  onChange,
  label,
  error,
  touched,
  classes,
  ...rest
}) => {
  const inputClasses = classNames({
    [classes.inputComponent]: classes.inputComponent,
    [classes.inputError]: error,
  })
  const feedBackClasses = classNames({
    FormField__Feedback: !classes.inputFeedback,
    [classes.inputFeedback]: classes.inputFeedback,
  })
  const inputWrapperClasses = classNames({
    FormField: !classes.inputWrapper,
    [classes.inputWrapper]: classes.inputWrapper,
  })
  const labelClasses = classNames({
    FormField__Label: !classes.label,
    [classes.label]: classes.label,
  })
  return (
    <div className={inputWrapperClasses}>
      <label className={labelClasses} htmlFor={rest.name}>
        {label}
      </label>
      <CurrencyFormat
        onValueChange={onChange}
        displayType="input"
        decimalSeparator=","
        thousandSeparator="."
        decimalScale={2}
        prefix="R$ "
        className={inputClasses}
        {...rest}
      />
      {error && touched && <div className={feedBackClasses}>{error}</div>}
    </div>
  )
}

CurrencyField.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  label: PropTypes.string,
  error: PropTypes.string,
  touched: PropTypes.bool,
  classes: PropTypes.shape({
    inputComponent: PropTypes.string,
    inputError: PropTypes.string,
    inputFeedback: PropTypes.string,
    inputWrapper: PropTypes.string,
    label: PropTypes.string,
  }),
}

CurrencyField.defaultProps = {
  placeholder: 0,
  type: 'text',
  error: '',
  name: 'default',
  touched: false,
  classes: {
    inputWrapper: 'FormField',
    inputComponent: 'FormField__Input',
    inputFeedback: 'FormField__Feedback',
    inputError: 'FormField__Input--error',
    label: 'FormField__Label',
  },
}

export default CurrencyField
