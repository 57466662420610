import axios from 'axios'
import { headers } from 'services/auth'

const API_URL = process.env.REACT_APP_API_URL
const GET_OPTIONALS = `${API_URL}/imoveis/opcionaisporcategoria`

export const getOptionalsByCategory = categories => {
  let categoriesParam = ''
  categories.forEach((category, index) => {
    if (index === categories.length - 1) categoriesParam += category
    else categoriesParam += `${category},`
  })

  return axios.get(`${GET_OPTIONALS}/${categoriesParam}`, { headers })
}
