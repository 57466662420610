import React from 'react'
import { authed } from 'services/auth'
import HeaderLoggedIn from './LoggedIn'
import HeaderLoggedOut from './LoggedOut'

const Header = ({ transparent, home }) =>
  authed() ? (
    <HeaderLoggedIn home={home} transparent={transparent} />
  ) : (
    <HeaderLoggedOut home={home} transparent={transparent} />
  )

export default Header
