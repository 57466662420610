import WebWorker from 'workers/workerSetup'
import realtyWorker from 'workers/realtiesWorker'

export const formatRealties = (hits, businessType, callback) => {
  const worker = new WebWorker(realtyWorker)
  worker.postMessage({ hits, businessType })
  worker.addEventListener('message', event => {
    callback && callback(event)
    worker.terminate()
  })
}
