import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { deburr } from 'lodash'
import { Button } from 'components/Buttons'
import I18n from 'locales'
import './index.css'

class Select extends Component {
  constructor() {
    super()
    this.state = {
      options: [],
      search: '',
      active: false,
      selected: '',
    }
  }

  componentDidMount() {
    this.setOptions()
  }

  componentWillMount() {
    document.addEventListener('mousedown', this.handleClick, false)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false)
  }

  handleClick = e => {
    if (this.node && this.node.contains(e.target)) {
      return
    }

    this.state.active && this.toggleContainer()
  }

  toggleContainer = () => {
    const { active } = this.state
    this.setState({ active: !active })
  }

  setOptions = () => {
    const { options } = this.props

    this.setState({ options: [...options] })
  }

  selectOption = selection => {
    this.setState({ selected: selection })
  }

  onOptionSearch = ({ target: { value } }) => {
    const { options } = this.props

    if (value) {
      this.setState({
        search: value,
        options: options.filter(({ label }) =>
          deburr(label)
            .toLowerCase()
            .includes(deburr(value).toLowerCase()),
        ),
      })
    } else {
      this.setState({
        search: value,
        options: [...options],
      })
    }
  }

  render() {
    const { name, label, error, classes, onChange } = this.props
    const { options, search, active, selected } = this.state
    const feedBackClasses = classNames({
      Select__Feedback: !classes.inputFeedback,
      [classes.inputFeedback]: classes.inputFeedback,
    })
    const labelClasses = classNames({
      Select__Label: !classes.label,
      [classes.label]: classes.label,
    })
    return (
      <div>
        <label className={labelClasses} htmlFor={name}>
          {label}
        </label>
        <Button
          className="SelectTypeahead"
          btnStyle="link"
          onClick={() => {
            this.toggleContainer()
            setTimeout(() => this.input.focus(), 500)
          }}
        >
          {selected || I18n.t('selectTypeahead.selectOption')}
        </Button>
        {active && (
          <div
            className="SelectTypeahead__OptionContainer"
            ref={el => (this.node = el)}
          >
            <input
              placeholder={I18n.t('selectTypeahead.searchAnOption')}
              value={search}
              onChange={this.onOptionSearch}
              ref={el => (this.input = el)}
              className="SelectTypeahead__SearchInput"
            />
            <div className="SelectTypeahead__ScrollContainer">
              {options.map(({ label, value }) => (
                <div
                  key={value}
                  value={value}
                  onClick={() => {
                    onChange(value)
                    this.toggleContainer()
                    this.selectOption(label)
                  }}
                >
                  {label}
                </div>
              ))}
            </div>
          </div>
        )}
        {error && <div className={feedBackClasses}>{error}</div>}
      </div>
    )
  }
}

Select.propTypes = {
  name: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  label: PropTypes.string,
  error: PropTypes.string,
  touched: PropTypes.bool,
  classes: PropTypes.shape({
    inputComponent: PropTypes.string,
    inputError: PropTypes.string,
    inputFeedback: PropTypes.string,
    inputWrapper: PropTypes.string,
    label: PropTypes.string,
  }),
}

Select.defaultProps = {
  placeholder: 0,
  type: 'text',
  error: '',
  touched: false,
  classes: {
    inputWrapper: 'Select',
    inputComponent: 'Select__Input',
    inputFeedback: 'Select__Feedback',
    inputError: 'Select__Input--error',
    label: 'Select__Label',
  },
}

export default Select
