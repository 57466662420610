import React from 'react'
import classNames from 'classnames'
import './index.css'

const ArrowSvgIcon = ({ height = 20, width = 20, className, up }) => {
  const classes = classNames('ArrowSvgIcon', className, {
    'ArrowSvgIcon--up': up,
  })
  const pathClasses = classNames('ArrowSvgIcon__Line', `${className}__Line`)

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={classes}
      height={height}
      width={width}
      viewBox="0 0 20 20"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 20h20V0H0z" />
        <path
          className={pathClasses}
          stroke="#797E85"
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M9.888 12.05l4.375-4.374M5.513 7.676l4.375 4.375"
        />
      </g>
    </svg>
  )
}

export default ArrowSvgIcon
