import React from 'react'
import classNames from 'classnames'
import Badge from 'react-badger'
import './index.css'

export default ({ className, ...props }) => {
  const classes = classNames('Badge', className)

  return <Badge {...props} className={classes} />
}
