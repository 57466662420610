import React from 'react'
import RCCheckbox from 'rc-checkbox'
import 'rc-checkbox/assets/index.css'
import './index.css'

const Checkbox = ({
  label,
  mobile = false,
  mobileSub = false,
  reverse = false,
  ...props
}) => {
  let checkClass = 'Checkbox__Input'
  let labelClass = 'Checkbox__Label'

  if (mobile) {
    checkClass = 'Checkbox__InputMobile'
    labelClass = 'Checkbox__LabelMobile'
  }

  if (mobileSub) {
    checkClass = 'Checkbox__InputMobileSub'
    labelClass = 'Checkbox__LabelMobileSub'
  }

  if (reverse) {
    checkClass = 'Checkbox__Input--reverse'
    labelClass = 'Checkbox__Label--reverse'
  }

  return (
    <label className="Checkbox">
      <RCCheckbox {...props} className={checkClass} />
      <span className={labelClass}>{label}</span>
    </label>
  )
}

export default Checkbox
