import React from 'react'
import { Table, Row, Col } from 'components/AlertTable/styles'
import { formatToLocale } from 'utils/numberFormatter'
import { Row as GRow, Col as GCol } from 'components/Grid'
import classNames from 'classnames'
import './index.css'

const renderRow = ({ key, price: { values }, area, totalArea, doc_count }) => (
  <Row key={`${key}${doc_count}`}>
    <Col width="25%" label="Localização">
      {key}
    </Col>
    <Col width="25%" label="Número de Imóveis">
      {doc_count}
    </Col>
    <Col width="25%" label="Valor médio (R$/m²)">
      {formatToLocale(
        values['50.0'] / (area.values['50.0'] || totalArea.values['50.0']),
        2,
        2,
      )}
    </Col>
    <Col width="25%" label="Valor médio (R$)">
      {formatToLocale(values['50.0'], 2, 2)}
    </Col>
  </Row>
)

const Statistics = ({ statistics, active, close }) => {
  const classes = classNames('Statistics', {
    'Statistics--active': active,
  })
  return (
    <div className={classes} data-tut="step-eight">
      <GRow end="xs">
        <GCol xs={12}>
          <i onClick={close} className="material-icons">
            close
          </i>
        </GCol>
      </GRow>
      <GRow>
        <GCol xs={12}>
          <h1 className="Statistics__Header">Estatísticas</h1>
        </GCol>
      </GRow>
      <Table>
        <Row header>
          <Col header width="25%">
            Localização
          </Col>
          <Col header width="25%">
            Número de Imóveis
          </Col>
          <Col header width="25%">
            Valor médio (R$/m²)
          </Col>
          <Col header width="25%">
            Valor médio (R$)
          </Col>
        </Row>
        {statistics.map(item => renderRow(item))}
      </Table>
    </div>
  )
}

const StatisticsMobile = ({ statistics, active, close }) => {
  const classes = classNames('Statistics__Pane', {
    'Statistics__Pane--hidden': !active,
  })
  return (
    <div className={classes}>
      <div className="Statistics__Modal">
        <GRow end="xs">
          <GCol xs={12}>
            <i onClick={close} className="material-icons">
              close
            </i>
          </GCol>
        </GRow>
        <GRow>
          <GCol xs={12}>
            <h1 className="Statistics__Header">Estatísticas</h1>
          </GCol>
        </GRow>
        <Table className="Statistics__Modal__Table">
          <Row header>
            <Col header width="25%">
              Localização
            </Col>
            <Col header width="25%">
              Número de Imóveis
            </Col>
            <Col header width="25%">
              Valor médio (R$/m²)
            </Col>
            <Col header width="25%">
              Valor médio (R$)
            </Col>
          </Row>
          <div className="Statistics__Modal__Body">
            {statistics.map(item => renderRow(item))}
          </div>
        </Table>
      </div>
    </div>
  )
}

export default (window.innerWidth < 768 ? StatisticsMobile : Statistics)
