import React from 'react'
import classNames from 'classnames'
import './index.css'

const SearchSvgIcon = ({ height, width, className }) => {
  const classes = classNames('SearchSvgIcon', {
    [className]: className,
  })

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      className={classes}
      viewBox="0 0 32 32"
    >
      <g fill="none" fillRule="evenodd">
        <path d="M0 32h32V0H0z" />
        <g stroke="#29313E" strokeWidth="2">
          <path d="M14.15 6.57a7.003 7.003 0 1 0 0 14.005 7.003 7.003 0 0 0 0-14.005z" />
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M18.724 18.902l5.728 5.728"
          />
        </g>
      </g>
    </svg>
  )
}

export default SearchSvgIcon
