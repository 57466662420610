import React from 'react'
import classNames from 'classnames'
import './index.css'
import PinLogo from 'components/PinLogo'

const Spinner = ({ visible, width, height }) => {
  const classes = classNames({ 'Spinner--hidden': !visible, Spinner: visible })
  const pinClasses = classNames({
    'Spinner__Pin--hidden': !visible,
    Spinner__Pin: visible,
  })
  return (
    <div className={classes}>
      <PinLogo
        width={width || 100}
        height={height || 100}
        className={pinClasses}
      />
    </div>
  )
}

export default Spinner
