import React from 'react'
import { Button } from 'components/Buttons'
import I18n from 'locales'
import './index.css'

const StopDrawingButton = ({ onClick }) => (
  <Button className="Search__MapButton Search__Animated" onClick={onClick}>
    <i className="material-icons DrawButton__Icon">check</i>
    {I18n.t('components.drawButton.stopDrawing')}
  </Button>
)

export default StopDrawingButton
