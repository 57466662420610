import React, { Component } from 'react'
import { deburr } from 'lodash'
import { createFilter } from 'components/Form'
import './index.css'

class FilterNeighborhood extends Component {
  constructor() {
    super()
    this.state = {
      active: false,
      neighborhoods: [],
    }
    this.neighborhoods = undefined
  }

  componentDidMount(prevProps, prevState) {
    this.setNeighborhoods(this.props.loadedNeighborhoods)
  }

  componentWillMount() {
    document.addEventListener('mousedown', this.handleClick, false)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false)
  }

  toggleContainer = () => {
    this.setState(
      state => ({ active: !state.active }),
      () => {
        if (this.state.active) {
          setTimeout(() => this.input.focus(), 500)
        }
      },
    )
  }

  handleClick = e => {
    if (this.node && this.node.contains(e.target)) {
      return
    }

    this.state.active && this.toggleContainer()
  }

  setNeighborhoods = neighborhoods => {
    this.setState({ neighborhoods })
  }

  render() {
    const { active, neighborhoods } = this.state
    return (
      <div className="FilterNeighborhood">
        {createFilter({
          icon: { size: 20 },
          label: 'Bairros',
          isOpen: this.state.active,
          onClick: this.toggleContainer,
        })}
        {active && (
          <div
            className="FilterNeighborhood__Container"
            ref={el => (this.node = el)}
          >
            <input
              className="FilterNeighborhood__Input"
              placeholder="Pesquise aqui"
              ref={el => (this.input = el)}
              onChange={({ target: { value } }) => {
                this.setState({
                  neighborhoods: this.props.loadedNeighborhoods.filter(
                    ({ name }) =>
                      deburr(name.toLowerCase()).includes(
                        deburr(value.toLowerCase()),
                      ),
                  ),
                })
              }}
            />
            <div className="FilterNeighborhood__ScrollContainer">
              {neighborhoods.length > 0 &&
                neighborhoods.map(({ id, name, path }) => (
                  <div
                    className="FilterNeighborhood__Option"
                    key={id}
                    onClick={() => {
                      this.props.clearClusters()
                      this.props.mapService.addPolygonFromPath({ path })
                      this.props.onSearch()
                    }}
                  >
                    {name}
                  </div>
                ))}
            </div>
          </div>
        )}
      </div>
    )
  }
}

export default FilterNeighborhood
