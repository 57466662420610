export const formatTelephone = phone => {
  if (phone) {
    let docAsString = phone + ''
    let match = /^(\d{2})(\d{5})(\d{4})/
    if (docAsString.length < 11) {
      match = /^(\d{2})(\d{4})(\d+)/
    }
    return docAsString.replace(match, '($1) $2-$3')
  }
  return ''
}
