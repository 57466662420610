import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Autosuggest from 'react-autosuggest'
import './index.css'

class AutoCompleteInput extends Component {
  getSuggestions = (value = '') => {
    const { suggestions } = this.props
    const inputValue = value.trim().toLowerCase()
    const inputLength = inputValue.length

    return inputLength === 0
      ? []
      : suggestions.filter(
          item => item.name.toLowerCase().slice(0, inputLength) === inputValue,
        )
  }

  getSuggestionValue = suggestion => suggestion.name

  onSuggestionsFetchRequested = ({ value }) => {
    this.setState({
      suggestions: this.getSuggestions(value),
    })
  }

  onSuggestionsClearRequested = () => {
    this.setState({
      suggestions: this.props.suggestions,
    })
  }

  renderSuggestionsContainer = ({ containerProps, children, query }) => (
    <div
      {...containerProps}
      className={this.props.classes.optionContainer}
      style={{ overflowY: 'auto', maxHeight: '200px' }}
    >
      {children}
    </div>
  )

  renderSuggestion = (suggestion, { isHighlighted }) => {
    const classes = classNames({
      AutoComplete__Option: !isHighlighted,
      'AutoComplete__Option--active': isHighlighted,
    })

    return (
      <div>
        <div className={classes}>{suggestion.name}</div>
      </div>
    )
  }

  render() {
    const {
      name,
      type,
      placeholder,
      label,
      error,
      classes,
      value,
      onChange,
      onBlur,
      onFocus,
      suggestions,
      disabled,
    } = this.props
    const inputClasses = classes.inputComponent
      ? classNames({
          [classes.inputComponent]: classes.inputComponent,
          [classes.inputError]: error,
        })
      : classNames('AutoComplete__Input')
    const feedBackClasses = classNames({
      AutoComplete__Feedback: !classes.inputFeedback,
      [classes.inputFeedback]: classes.inputFeedback,
    })
    const inputWrapperClasses = classNames({
      AutoComplete: !classes.inputWrapper,
      [classes.inputWrapper]: classes.inputWrapper,
    })
    const labelClasses = classNames({
      AutoComplete__Label: !classes.label,
      [classes.label]: classes.label,
    })
    const inputProps = {
      placeholder,
      value,
      onChange,
      onFocus,
      onBlur,
      className: inputClasses,
      type,
      autoComplete: this.props.autoComplete || 'off',
      name,
      disabled,
    }
    return (
      <div className={inputWrapperClasses}>
        <label className={labelClasses} htmlFor={name}>
          {label}
        </label>
        <Autosuggest
          suggestions={suggestions}
          onSuggestionsFetchRequested={this.onSuggestionsFetchRequested}
          onSuggestionsClearRequested={this.onSuggestionsClearRequested}
          getSuggestionValue={this.getSuggestionValue}
          renderSuggestion={this.renderSuggestion}
          renderSuggestionsContainer={this.renderSuggestionsContainer}
          inputProps={inputProps}
          {...this.props}
        />
        {error && <div className={feedBackClasses}>{error}</div>}
      </div>
    )
  }
}
AutoCompleteInput.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.string,
  placeholder: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  label: PropTypes.string,
  error: PropTypes.string,
  touched: PropTypes.bool,
  classes: PropTypes.shape({
    inputComponent: PropTypes.string,
    inputError: PropTypes.string,
    inputFeedback: PropTypes.string,
    inputWrapper: PropTypes.string,
    label: PropTypes.string,
  }),
}

AutoCompleteInput.defaultProps = {
  placeholder: 0,
  type: 'text',
  error: '',
  touched: false,
  classes: {
    inputWrapper: 'AutoComplete',
    inputComponent: 'AutoComplete__Input',
    optionContainer: 'AutoComplete__OptionContainer',
    inputFeedback: 'AutoComplete__Feedback',
    inputError: 'AutoComplete__Input--error',
    label: 'AutoComplete__Label',
  },
}

export default AutoCompleteInput
