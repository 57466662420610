import React, { Component } from 'react'
import { Formover } from 'components/Form'
import { Row, Col } from 'components/Grid'
import { Button } from 'components/Buttons'
import Switch from 'components/Form/Switch'
import Badge from 'components/Badge'
import I18n from 'locales'
import LayersIcon from 'components/SvgIcons/LayersSvgIcon'
import './index.css'

const getCount = values => {
  let count = 0
  for (let value of Object.keys(values)) {
    if (values[value]) count++
  }
  return count
}

class VisualizeButton extends Component {
  componentWillMount() {
    document.addEventListener('mousedown', this.handleClickVisualize, false)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickVisualize, false)
  }

  handleClickVisualize = e => {
    if (this.VisualizeButton && this.VisualizeButton.contains(e.target)) {
      return
    }

    this.isInViewport() && this.submit && this.submit()
  }

  isInViewport(offset = 0) {
    if (!this.pop) return false
    const top = this.pop.getBoundingClientRect().top
    return top + offset >= 0 && top - offset <= window.innerHeight
  }

  render() {
    const { initialValues, onSubmit, zoning } = this.props
    const count = getCount(initialValues)
    return (
      <div ref={el => (this.VisualizeButton = el)}>
        <Formover
          onSubmit={(values, formik, formover) => onSubmit(values, formover)}
          initialValues={{ ...initialValues }}
          className="VisualizeButton"
          placement="top"
          style={{ bottom: 0 }}
          target={({ getTargetProps }) => {
            const { innerRef, ...innerProps } = getTargetProps()
            return (
              <Button
                ref={innerRef}
                {...innerProps}
                className="Search__MapButton"
              >
                <LayersIcon className="VisualizeButton__Icon" />
                {I18n.t('components.visualizeButton.visualize')}
                {count > 0 ? (
                  <Badge
                    anchor="inline"
                    size={20}
                    shadow={false}
                    className="VisualizeButton__Badge"
                  >
                    {count}
                  </Badge>
                ) : null}
              </Button>
            )
          }}
        >
          {({ values, setFieldValue, ...props }) => {
            this.submit = props.handleSubmit
            return (
              <form
                className="container VisualizeButton__Popover"
                onSubmit={props.handleSubmit}
                ref={el => (this.pop = el)}
              >
                <Row between="xs">
                  <Switch
                    name="neighborhoods"
                    checked={values.neighborhoods}
                    onChange={value => {
                      setFieldValue('neighborhoods', value)
                      setFieldValue('zones', false)
                    }}
                    label={I18n.t('components.visualizeButton.neighborhoods')}
                  />
                </Row>
                <Row between="xs">
                  <Switch
                    name="zones"
                    disabled={!zoning}
                    checked={values.zones}
                    onChange={value => {
                      setFieldValue('zones', value)
                      setFieldValue('neighborhoods', false)
                    }}
                    label={I18n.t('components.visualizeButton.zones')}
                  />
                </Row>
                <Row between="xs">
                  <Switch
                    name="satellite"
                    checked={values.satellite}
                    onChange={value => {
                      setFieldValue('satellite', value)
                    }}
                    label={I18n.t('components.visualizeButton.satellite')}
                  />
                </Row>
                <Row between="xs">
                  <Col>
                    <Button
                      className="FilterPriceRange__Popover__Cancel"
                      btnStyle="link"
                      onClick={props.toggle}
                    >
                      {I18n.t('components.actions.cancel')}
                    </Button>
                  </Col>
                  <Col>
                    <Button btnStyle="link" type="submit">
                      {I18n.t('components.actions.apply')}
                    </Button>
                  </Col>
                </Row>
              </form>
            )
          }}
        </Formover>
      </div>
    )
  }
}

export default VisualizeButton
