import { getTransactionType } from 'utils/getTransactionType'

export const sortEnum = priceType => ({
  AdDate: 'created_at',
  Bedrooms: 'features_bedroom',
  Garages: 'features_garage',
  Price: getTransactionType(priceType),
  TotalArea: 'total_area',
  UsefulArea: 'area',
  Id: '_id',
})
