import React from 'react'
import Logo from 'components/PinLogo'
import MobileNavMenu from 'components/MobileNavMenu'
import classNames from 'classnames'
import { authed, userInfo, updateCredential } from 'services/auth'
import { Row, Col } from 'components/Grid'
import { PHONE_MASK, TELEPHONE_VALIDATION } from 'utils/mask'
import { updateUserInfo } from 'services/user'
import { Formik, Form, InputMask } from 'components/Form'
import { Button } from 'components/Buttons'
import * as Yup from 'yup'
import i18n from 'locales'
import './index.css'

const PhoneNumberSchema = Yup.object().shape({
  PhoneNumber: Yup.string()
    .matches(TELEPHONE_VALIDATION, 'WhatsApp Inválido')
    .required(i18n.t('login.required')),
})

const updatePhoneNumber = ({ PhoneNumber }) => {
  const user = userInfo()
  const payload = {
    CidadeId: user.cityId,
    Endereco: user.address,
    EstadoId: user.ufId,
    GestorId: user.managerId,
    Id: user.userId,
    ImobiliariaId: user.realStateId,
    Nome: user.username,
    TipoPerfil: user.profileType,
    Telefone: PhoneNumber,
  }

  updateUserInfo(payload)
    .then(({ status }) => {
      if (status === 200) {
        updateCredential(payload)
        this.setState({ loading: false })
        window.M.toast({
          html: i18n.t('common.successfullyAltered'),
          classes: 'Toast--success',
        })
      } else {
        this.setState({ loading: false })
        window.M.toast({
          html: i18n.t('common.fail'),
          classes: 'Toast--failure',
        })
      }
    })
    .catch(err => {
      console.log(err)
      this.setState({ loading: false })
      window.M.toast({
        html: i18n.t('common.fail'),
        classes: 'Toast--failure',
      })
    })
    .finally(() => window.location.reload())
}

const MobileHeader = ({ loggedIn, searchBar, transparent, home }) => {
  const classes = classNames({
    HeaderMobile: !transparent,
    'HeaderMobile--transparent': transparent,
    'HeaderMobile--home': home,
  })
  return (
    <header className={classes}>
      <div className="HeaderMobile__Container">
        <div className="HeaderMobile__LogoColumn">
          <a href={i18n.t('routes.search')}>
            <Logo title="EEmovel" />
          </a>
        </div>
        <div className="HeaderMobile__SearchBarColumn">
          {searchBar && searchBar}
        </div>
        <div className="HeaderMobile__MenuColumn">
          <MobileNavMenu loggedIn />
        </div>
      </div>
      {authed() && !userInfo().phone && userInfo().profileType === 2 && (
        <div className="HeaderMobile__Pane">
          <div className="HeaderMobile__Modal">
            <Row>
              <Col xs={12}>
                <h1 className="HeaderMobile__ModalHeader">
                  Complete seu cadastro!
                </h1>
              </Col>
            </Row>
            <Formik
              validationSchema={PhoneNumberSchema}
              initialValues={{ PhoneNumber: '' }}
              onSubmit={updatePhoneNumber}
            >
              {({
                handleSubmit,
                handleChange,
                values: { PhoneNumber },
                errors,
              }) => (
                <Form onSubmit={handleSubmit}>
                  <Row className="HeaderMobile__FormRow">
                    <Col xs={12}>
                      <InputMask
                        label="Insira o número do seu WhatsApp"
                        error={errors.PhoneNumber}
                        name="PhoneNumber"
                        placeholder="WhatsApp"
                        onChange={handleChange}
                        value={PhoneNumber}
                        mask={PHONE_MASK[0]}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col xs={12}>
                      <Button btnStyle="primary" block type="submit">
                        {i18n.t('common.save')}
                      </Button>
                    </Col>
                  </Row>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      )}
    </header>
  )
}

export default MobileHeader
