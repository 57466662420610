export const kFormatter = num => {
  if (num > 999) {
    if (String(num).length >= 7) {
      if (num % 1000000 === 0) return `${num / 1000000}kk`
      else return `${(num / 1000000).toFixed(1)}kk`
    } else {
      if (num % 1000 === 0) return `${num / 1000}k`
      else return `${(num / 1000).toFixed(1)}k`
    }
  } else {
    return num
  }
}

export const formatToLocale = (num, min = 2, max = 2) => {
  return Number(num).toLocaleString('pt-BR', {
    minimumFractionDigits: min,
    maximumFractionDigits: max,
  })
}
