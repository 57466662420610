import styled from 'styled-components'

export const Table = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`
export const Row = styled.div`
  display: ${({ header }) => (header ? 'none' : 'flex')};
  flex-direction: column;
  border-bottom: ${({ noBorder }) => (noBorder ? '0' : ' 1px solid #e1e2e0')};
  width: 100%;
  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
  }
  &:hover {
    background-color: ${({ hover }) => (hover ? '#EEEEEE' : 'inherit')};
    cursor: ${({ hover }) => (hover ? 'pointer' : 'unset')};
  }
`
export const Col = styled.div`
  min-width: 0;
  width: 100%;
  font-weight: ${({ header }) => (header ? 'bold' : 'normal')};
  text-align: left;
  position: relative
  padding: 5px 5px 5px 0;
  color: #29313e;
  overflow-x: auto;
  &::-webkit-scrollbar {
    display: none;
  }
  &::before {
    content: '${({ label }) => (label ? `${label}: ` : '')}';
    margin-right: 5px;
    font-weight: bold;
  }
  @media (min-width: 768px) {
    overflow-x: hidden;
    display: flex;
    flex-direction: ${({ action }) => (action ? 'column' : 'row')};
    justify-content: ${({ textCenter }) =>
      textCenter ? 'center' : 'flex-start'};
    align-items: center;
    padding: ${({ lessPadding }) =>
      lessPadding ? '4px 5px 4px 0' : '15px 5px 15px 0'};
    width: ${({ width }) => width};
    &::before {
      content: '';
    }
    span {
      overflow-x: hidden;
      overflow-y: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
`
