import React, { Component } from 'react'
import Tour from 'reactour'
import { Row, Col } from 'components/Grid'
import { Button } from 'components/Buttons'
import { COLORS } from 'constants/colors'
import I18n from 'locales'
import './index.css'

class TourComponent extends Component {
  renderSkipButton = () => (
    <Row style={{ marginTop: 8 }}>
      <Col xs={12}>
        <Button onClick={this.props.onClose} block btnStyle="flat" btnSize="xs">
          {I18n.t('tour.skipTutorial')}
        </Button>
      </Col>
    </Row>
  )

  steps = () => [
    {
      selector: '[data-tut="step-one"]',
      content: () => (
        <div>
          {I18n.t('tour.stepOne')}
          {this.renderSkipButton()}
        </div>
      ),
      position: 'right',
      observe: '[data-tut="step-one"]',
    },
    {
      selector: '[data-tut="step-two"]',
      content: () => (
        <div>
          {I18n.t('tour.stepTwo')}
          <br />
          {this.renderSkipButton()}
        </div>
      ),
      position: 'right',
      observe: '[data-tut="step-two"]',
    },
    {
      selector: '[data-tut="step-three"]',
      content: () => (
        <div>
          {I18n.t('tour.stepThree')}
          <br />
          {this.renderSkipButton()}
        </div>
      ),
      observe: '[data-tut="step-three"]',
      position: 'bottom',
    },
    {
      selector: '[data-tut="step-four"]',
      content: () => (
        <div>
          {I18n.t('tour.stepFour')}
          <br />
          {this.renderSkipButton()}
        </div>
      ),
      observe: '[data-tut="step-four"]',
    },
    {
      selector: '[data-tut="step-five"]',
      content: () => (
        <div>
          {I18n.t('tour.stepFive')}
          <br />
          {this.renderSkipButton()}
        </div>
      ),
      observe: '[data-tut="step-five"]',
    },
    {
      selector: '[data-tut="step-six"]',
      content: () => (
        <div>
          {I18n.t('tour.stepSix')}
          <br />
          {this.renderSkipButton()}
        </div>
      ),
      observe: '[data-tut="step-six"]',
    },
    {
      selector: '[data-tut="step-seven"]',
      content: () => (
        <div>
          {I18n.t('tour.stepSeven')}
          <br />
          {this.renderSkipButton()}
        </div>
      ),
      observe: '[data-tut="step-seven"]',
    },
    {
      selector: '[data-tut="step-eight"]',
      content: () => (
        <div>
          {I18n.t('tour.stepEight')}
          <br />
          {this.renderSkipButton()}
        </div>
      ),
      observe: '[data-tut="step-eight"]',
    },
    {
      selector: '[data-tut="step-nine"]',
      content: () => (
        <div>
          {I18n.t('tour.stepNine')}
          <br />
          {this.renderSkipButton()}
        </div>
      ),
      observe: '[data-tut="step-nine"]',
    },
    {
      selector: '[data-tut="step-ten"]',
      content: () => (
        <div>
          {I18n.t('tour.stepTen')}
          <br />
          {this.renderSkipButton()}
        </div>
      ),
      observe: '[data-tut="step-ten"]',
    },
    {
      selector: '[data-tut="step-eleven"]',
      content: () => (
        <div>
          {I18n.t('tour.stepEleven')}
          <br />
          {this.renderSkipButton()}
        </div>
      ),
      observe: '[data-tut="step-eleven"]',
    },
    {
      selector: '[data-tut="step-twelve"]',
      content: () => (
        <div>
          {I18n.t('tour.stepTwelve')}
          <br />
          {this.renderSkipButton()}
        </div>
      ),
      observe: '[data-tut="step-twelve"]',
    },
    {
      selector: '[data-tut="step-thirteen"]',
      content: () => (
        <div>
          <Row>
            <Col xs={12}>{I18n.t('tour.stepThirteenPartOne')}</Col>
          </Row>
          <br />
          <Row>
            <Col xs={12}>{I18n.t('tour.stepThirteenPartTwo')}</Col>
          </Row>
          <br />
          <Row>
            <Col xs={12}>
              <Button
                onClick={this.props.onClose}
                block
                btnStyle="primary"
                btnSize="xs"
              >
                {I18n.t('tour.finish')}
              </Button>
            </Col>
          </Row>
        </div>
      ),
      observe: '[data-tut="step-thirteen"]',
    },
  ]

  render() {
    const { isOpen, onClose } = this.props
    return (
      <Tour
        accentColor={COLORS.gray28}
        steps={this.steps()}
        isOpen={isOpen}
        onRequestClose={onClose}
        closeWithMask={false}
        showButtons={false}
        showNavigation={false}
        showCloseButton={false}
        showNavigationNumber={false}
        disableKeyboardNavigation={['esc', 'right', 'left']}
        maskSpace={1}
        {...this.props}
      />
    )
  }
}
export default TourComponent
