import React from 'react'
import classNames from 'classnames'
import './index.css'

const Logo = ({ className, height = 60, width = 60 }) => {
  const classes = classNames({
    [className]: className,
  })
  return (
    <svg
      className={classes}
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      height={height}
      width={width}
      viewBox="0 0 104 104"
      style={{ enableBackground: 'new 0 0 104 104' }}
      xmlSpace="preserve"
    >
      <path
        id="XMLID_7_"
        className="MinLogo__st0"
        d="M92,104H12c-6.6,0-12-5.4-12-12V12C0,5.4,5.4,0,12,0h80c6.6,0,12,5.4,12,12v80
	        C104,98.6,98.6,104,92,104z"
      />
      <polygon
        id="XMLID_6_"
        className="MinLogo__st1"
        points="85,49.2 77.3,61.3 69.6,49.2 59.1,49.2 77.3,77.7 95.4,49.2 "
      />
      <path
        id="XMLID_5_"
        className="MinLogo__st2"
        d="M90.6,31.4c-3.6-3.6-8.3-5.5-13.4-5.5c-5,0-9.8,2-13.4,5.5c-3.6,3.6-5.5,8.3-5.5,13.4
	        c0,0.2,0,0.5,0,0.7h9.8c0-0.2,0-0.5,0-0.7c0-5,4.1-9.1,9.1-9.1c5,0,9.1,4.1,9.1,9.1c0,0.2,0,0.5,0,0.7h9.8c0-0.2,0-0.5,0-0.7
	        C96.2,39.7,94.2,34.9,90.6,31.4z"
      />
      <g id="XMLID_2_">
        <path
          id="XMLID_3_"
          className="MinLogo__st3"
          d="M15.6,25.9h39.9v10.2H27.1v10.6h25v10.2h-25v11h28.8v10.2H15.6V25.9z"
        />
      </g>
    </svg>
  )
}

export default Logo
