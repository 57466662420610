import React from 'react'
import RCSwitch from 'rc-switch'
import { Row, Col } from 'components/Grid'
import 'rc-switch/assets/index.css'
import './index.css'

const Switch = ({ label, ...props }) => (
  <Col xs={12}>
    <Row>
      <Col xs={10}>
        <label className="Switch__Label">{label}</label>
      </Col>
      <Col xs={2}>
        <RCSwitch {...props} className="Switch" />
      </Col>
    </Row>
  </Col>
)

export default Switch
