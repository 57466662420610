export const PaginateArray = (array, pgSize = 15) => {
  if (!array || array.length <= 0) return []
  const step = [...array]
  const totalPage = array.length / pgSize

  let paginatedArray = []
  for (let i = 0; i < totalPage; i++) {
    paginatedArray.push(step.splice(0, pgSize))
  }
  return paginatedArray
}

export const DepaginateArray = array => {
  return [].concat(...array)
}
