import React, { Component } from 'react'
import classNames from 'classnames'
import { Form, createFilter, Checkbox, Formik } from 'components/Form'
import { FastField } from 'formik'
import { Col, Row } from 'components/Grid'
import { Button } from 'components/Buttons'
import I18n from 'locales'
import { SearchConsumer } from 'context/Search'
import './index.css'

class FilterBusinessType extends Component {
  constructor() {
    super()
    this.state = {
      active: false,
    }
  }

  toggleContainer = () => {
    this.setState(state => ({ active: !state.active }))
  }

  componentWillMount() {
    document.addEventListener('mousedown', this.handleClick, false)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false)
  }

  handleClick = e => {
    if (this.node && this.node.contains(e.target)) {
      return
    }

    this.state.active && this.submit && this.submit()
  }

  render() {
    const { className } = this.props
    const { active } = this.state
    const classes = classNames('SearchFilter', 'FilterBusinessType', className)
    return (
      <SearchConsumer>
        {({ onSubmit, filters: { businessType, privateBusiness }, error }) => (
          <div style={{ position: 'relative', zIndex: 2 }}>
            <div className={classes} data-tut="step-one">
              {createFilter({
                icon: { size: 20 },
                error: error.businessType,
                label: privateBusiness
                  ? `${businessType} Particular`
                  : businessType || 'Tipo de Negócio',
                isOpen: this.state.active,
                onClick: this.toggleContainer,
              })}
              {active && (
                <div className="FilterContainer">
                  <Formik
                    onSubmit={onSubmit}
                    initialValues={{ businessType, privateBusiness }}
                  >
                    {({ values, ...props }) => {
                      this.submit = props.submitForm
                      return (
                        <Form>
                          <div ref={el => (this.node = el)}>
                            <Row>
                              <Col>
                                <FastField
                                  component={Checkbox}
                                  name="businessType"
                                  label="Venda"
                                  checked={values.businessType === 'Venda'}
                                  onChange={() =>
                                    props.setFieldValue('businessType', 'Venda')
                                  }
                                />
                              </Col>
                            </Row>
                            <Row>
                              <Col>
                                <FastField
                                  component={Checkbox}
                                  label="Locação"
                                  name="businessType"
                                  checked={values.businessType === 'Locação'}
                                  onChange={() =>
                                    props.setFieldValue(
                                      'businessType',
                                      'Locação',
                                    )
                                  }
                                />
                              </Col>
                            </Row>
                            <hr className="FilterBusinessType__HorizontalRule" />
                            <Row
                              start="xs"
                              middle="xs"
                              between="xs"
                              className="FilterBusinessType__Private"
                            >
                              <FastField
                                component={Checkbox}
                                name="privateBusiness"
                                label="Apenas particulares"
                                checked={values.privateBusiness}
                                onChange={({ target: { checked } }) =>
                                  props.setFieldValue(
                                    'privateBusiness',
                                    checked,
                                  )
                                }
                              />
                            </Row>
                            <Row between="xs">
                              <Col>
                                <Button
                                  className="FilterBusinessType__Popover__Cancel"
                                  btnStyle="link"
                                  onClick={this.toggleContainer}
                                >
                                  {I18n.t('components.actions.cancel')}
                                </Button>
                              </Col>
                              <Col>
                                <Button btnStyle="link" type="submit">
                                  {I18n.t('components.actions.ok')}
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </Form>
                      )
                    }}
                  </Formik>
                </div>
              )}
            </div>
          </div>
        )}
      </SearchConsumer>
    )
  }
}

export default FilterBusinessType
