import React from 'react'
import { Checkbox } from 'components/Form'
import { Col, Row } from 'components/Grid'

export const getCount = values => {
  let count = 0

  if (values) {
    for (let value of Object.keys(values)) {
      if (values[value] && !value.includes('Filter')) count++
    }
  }

  return count
}

const selectCategory = ({ target: { checked } }, props, elements) => {
  elements.forEach(({ name }) => {
    props.setFieldValue(name, checked)
  })
}

const verifyAllOptions = (elements, values) => {
  for (let el of elements) {
    if (!values[el.name]) return false
  }

  return true
}

export const renderMicroCheckboxes = (
  elements,
  props,
  values,
  mobile = false,
) =>
  elements.map(({ name, prettyName }) => {
    return (
      <Row
        between="xs"
        key={name}
        className="FilterCategory__OptionContainer__SubOption"
      >
        <Col>
          <Checkbox
            name={name}
            mobileSub={mobile}
            label={prettyName}
            checked={values[name]}
            onChange={({ target: { checked } }) =>
              props.setFieldValue(name, checked)
            }
          />
        </Col>
      </Row>
    )
  })

export const renderMacroCheckbox = (
  name,
  label,
  props,
  elements,
  values,
  mobile = false,
) => (
  <Row between="xs">
    <Col>
      <Checkbox
        name={name}
        mobile={mobile}
        checked={verifyAllOptions(elements, values)}
        label={label}
        onChange={event => {
          selectCategory(event, props, elements)
          props.setFieldValue(name, event.target.checked)
        }}
      />
    </Col>
  </Row>
)
