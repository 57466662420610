import React, { Component } from 'react'
import classNames from 'classnames'
import { Form, CurrencyField, createFilter, Formik } from 'components/Form'
import { FastField } from 'formik'
import { Col, Row } from 'components/Grid'
import { Button } from 'components/Buttons'
import PriceRangeInfo from 'components/Search/FilterPriceRange/PriceRangeInfo'
import I18n from 'locales'
import { SearchConsumer } from 'context/Search'
import { kFormatter } from 'utils/numberFormatter'
import './index.css'

const formatingOptions = {
  displayType: 'input',
  decimalSeparator: ',',
  thousandSeparator: '.',
  decimalScale: 2,
  prefix: 'R$ ',
}

class FilterPriceRange extends Component {
  constructor() {
    super()
    this.state = {
      active: false,
    }
  }

  toggleContainer = () => {
    this.setState(state => ({ active: !state.active }))
  }

  handleOnBlur = ({ minPrice, maxPrice }, setFieldValue) => {
    if (minPrice && maxPrice && minPrice > maxPrice) {
      setFieldValue('minPrice', maxPrice)
      setFieldValue('maxPrice', minPrice)
    }
  }

  componentWillMount() {
    document.addEventListener('mousedown', this.handleClick, false)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false)
  }

  handleClick = e => {
    if (this.node && this.node.contains(e.target)) {
      return
    }

    this.state.active && this.submit && this.submit()
  }

  handleOnChange = (value, field, setFieldValue) => {
    setFieldValue(field, value)
  }

  verifyBeforeSubmit = (values, onSubmit) => {
    if (!values.minPrice) values.minPrice = 0
    if (!values.maxPrice) values.maxPrice = 0
    if (
      values.minPrice &&
      values.maxPrice &&
      values.minPrice > values.maxPrice
    ) {
      let step = values.maxPrice
      values.maxPrice = values.minPrice
      values.minPrice = step
    }
    onSubmit(values)
  }

  render() {
    const { className } = this.props
    const { active } = this.state
    const classes = classNames('SearchFilter', 'FilterPriceRange', className)
    return (
      <SearchConsumer>
        {({ onSubmit, filters: { priceRange } }) => (
          <div style={{ position: 'relative', zIndex: 2 }}>
            <div className={classes}>
              {createFilter({
                label:
                  priceRange.maxPrice || priceRange.minPrice
                    ? `R$ ${kFormatter(priceRange.minPrice)} - R$ ${kFormatter(
                        priceRange.maxPrice,
                      )}`
                    : 'Valor',
                icon: { size: 20 },
                isOpen: this.state.active,
                onClick: this.toggleContainer,
              })}
              {active && (
                <div className="FilterContainer">
                  <Formik
                    onSubmit={values =>
                      this.verifyBeforeSubmit(values, onSubmit)
                    }
                    initialValues={{
                      minPrice: 0,
                      maxPrice: 0,
                      ...priceRange,
                    }}
                  >
                    {({ values, setFieldValue, ...props }) => {
                      this.submit = props.handleSubmit
                      return (
                        <Form>
                          <div ref={el => (this.node = el)}>
                            <Row>
                              <Col>
                                <PriceRangeInfo />
                              </Col>
                            </Row>
                            <Row between="xs">
                              <Col xs={6}>
                                <FastField
                                  component={CurrencyField}
                                  label="Min"
                                  name="minPrice"
                                  pattern="[0-9]*"
                                  placeholder="De"
                                  value={values.minPrice || 0}
                                  onChange={({ floatValue }) =>
                                    this.handleOnChange(
                                      floatValue,
                                      'minPrice',
                                      setFieldValue,
                                    )
                                  }
                                  onBlur={() =>
                                    this.handleOnBlur(values, setFieldValue)
                                  }
                                  {...formatingOptions}
                                />
                              </Col>
                              <Col xs={6}>
                                <FastField
                                  component={CurrencyField}
                                  label="Max"
                                  pattern="[0-9]*"
                                  placeholder="Até"
                                  name="maxPrice"
                                  value={values.maxPrice || ''}
                                  onChange={({ floatValue }) =>
                                    this.handleOnChange(
                                      floatValue,
                                      'maxPrice',
                                      setFieldValue,
                                    )
                                  }
                                  onBlur={() =>
                                    this.handleOnBlur(values, setFieldValue)
                                  }
                                  {...formatingOptions}
                                />
                              </Col>
                            </Row>
                            <Row between="xs">
                              <Col>
                                <Button
                                  className="FilterPriceRange__Popover__Cancel"
                                  btnStyle="link"
                                  onClick={this.toggleContainer}
                                >
                                  {I18n.t('components.actions.cancel')}
                                </Button>
                              </Col>
                              <Col>
                                <Button btnStyle="link" type="submit">
                                  {I18n.t('components.actions.ok')}
                                </Button>
                              </Col>
                            </Row>
                          </div>
                        </Form>
                      )
                    }}
                  </Formik>
                </div>
              )}
            </div>
          </div>
        )}
      </SearchConsumer>
    )
  }
}

export default FilterPriceRange
