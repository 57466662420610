import React from 'react'

const PencilSvgIcon = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      className={className}
      viewBox="0 0 20 20"
    >
      <path
        fill="#797E85"
        fillRule="nonzero"
        d="M17.658 6.636l-1.657 1.657L12.708 5l1.646-1.646c.431-.409.949-.409 1.263-.033l2.03 2.033c.438.376.438.881.011 1.282zM6.986 16.696l-3.348.67.67-3.35 2.678 2.68zm1.016-.4L4.707 13 12 5.707 15.292 9l-7.29 7.296zM18.325 4.62l-1.971-1.973c-.671-.808-1.858-.808-2.698-.009l-2.009 2.01-8 7.998a.526.526 0 0 0-.133.25l-.004.006-.006.032c-.003.017-.01.034-.01.052l-.984 4.92a.5.5 0 0 0 .589.588l4.913-.985c.026 0 .05-.01.075-.015l.016-.004h.003a.522.522 0 0 0 .248-.134c.001 0 .003 0 .004-.002l7.99-7.996.006-.004 2-2c.841-.79.842-1.99-.029-2.734z"
      />
    </svg>
  )
}

export default PencilSvgIcon
