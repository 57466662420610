import React from 'react'
import { Col, Row, Grid } from 'components/Grid'
import { Button } from 'components/Buttons'
import { FadeLoader as Loader } from 'react-spinners'
import { COLORS } from 'constants/colors'
import CurrencyFormat from 'react-currency-format'
import classNames from 'classnames'
import './index.css'

const today = new Date()

const renderRealty = (
  {
    content: {
      Id,
      AdDate,
      Address,
      Bedrooms,
      Building,
      Category,
      Cep,
      City,
      Garages,
      Link,
      MeterPrice,
      Neighborhood,
      Price,
      RealState,
      TotalArea,
      UsefulArea,
      UsefulMeterPrice,
    },
    businessType,
  },
  openReportModal,
) => {
  let tempAdDate = new Date(AdDate)
  tempAdDate.setDate(tempAdDate.getDate() + 1)

  const adDate = Math.round((today - tempAdDate) / 86400000) + ' dia(s)'
  const rowClasses = classNames('InfoWindow__Row')
  const capitalizedClasses = classNames('InfoWindow--capitalized')
  const overFlowHiddenCapitalizedClasses = classNames(
    'InfoWindow--overflowhidden',
    'InfoWindow--capitalized',
  )
  const titleClasses = classNames('InfoWindow__Body__Content__Title')
  const contentClasses = classNames('InfoWindow__Body__Content')
  const timestamp = new Date()
    .toDateString()
    .split(' ')
    .join('-')

  return (
    <Grid fluid className={contentClasses}>
      <Row between="xs" center="xs" className={titleClasses}>
        <Col xs={12} md={11} className={capitalizedClasses}>
          <h2>
            {Category} - {businessType}
          </h2>
        </Col>
      </Row>
      <Row between="xs" start="xs" className={rowClasses}>
        <Col xs={12} className={capitalizedClasses}>
          <b>Endereço: </b> {Address && `${Address} - `}
          {Neighborhood && `${Neighborhood}, `}
          {City && `${City}`}
          {Cep && `, ${Cep}`}
        </Col>
      </Row>
      <Row between="xs" start="xs" className={rowClasses}>
        <Col xs={12} md={6} className={capitalizedClasses}>
          <b>Edificio: </b> {Building || '-'}
        </Col>
        <Col xs={12} md={5}>
          <b>Valor (R$): </b>
          <CurrencyFormat
            thousandSeparator="."
            decimalSeparator=","
            decimalScale={2}
            fixedDecimalScale
            value={Price}
            displayType="text"
          />
        </Col>
      </Row>
      <Row between="xs" start="xs" className={rowClasses}>
        <Col xs={12} md={6}>
          <b>Dormitórios: </b> {Bedrooms}
        </Col>
        <Col xs={12} md={5}>
          <b>Área Total (m²): </b>
          {TotalArea}
        </Col>
      </Row>
      <Row between="xs" start="xs" className={rowClasses}>
        <Col md={6} xs={12}>
          <b>Garagens: </b>
          {Garages}
        </Col>
        <Col md={5} xs={12}>
          <b>Área Útil (m²): </b>
          {UsefulArea}
        </Col>
      </Row>
      <Row between="xs" start="xs" className={rowClasses}>
        <Col md={6} xs={12} className={overFlowHiddenCapitalizedClasses}>
          <b>Imobiliária: </b> {(RealState && RealState.toLowerCase()) || '-'}
        </Col>
        <Col md={5} xs={12}>
          <b>Valor/m² Total (R$): </b>
          {MeterPrice ? (
            <CurrencyFormat
              thousandSeparator="."
              decimalSeparator=","
              decimalScale={2}
              fixedDecimalScale
              value={MeterPrice}
              displayType="text"
            />
          ) : (
            '-'
          )}
        </Col>
      </Row>
      <Row between="xs" start="xs" className={rowClasses}>
        <Col md={6} xs={12}>
          <b>Anúncio/Inclusão: </b> {adDate}
        </Col>
        <Col md={5} xs={12}>
          <b>Valor/m² Útil (R$): </b>
          {UsefulMeterPrice ? (
            <CurrencyFormat
              thousandSeparator="."
              decimalSeparator=","
              decimalScale={2}
              fixedDecimalScale
              value={UsefulMeterPrice}
              displayType="text"
            />
          ) : (
            '-'
          )}
        </Col>
      </Row>
      <Row between="xs" center="xs" style={{ marginTop: 10 }}>
        <Col md={4} xs={12}>
          <Button
            type="submit"
            btnSize="sm"
            btnStyle="link"
            className="InfoWindow__Body__Content__Btn"
            onClick={() =>
              window.open(
                Link.includes('zapimoveis') ? `${Link}?${timestamp}` : Link,
                '_tab',
              )
            }
          >
            <i className="material-icons">visibility</i>
            Ver Anúncio
          </Button>
        </Col>
        <Col md={4} xs={12}>
          <Button
            btnSize="sm"
            btnStyle="link"
            className="InfoWindow__Body__Content__Btn"
            onClick={() => window.open(`/rgi/${Id}/${businessType}`, '_tab')}
          >
            <i className="material-icons">assessment</i>
            Relatório
          </Button>
        </Col>
        <Col md={4} xs={12}>
          <Button
            btnSize="sm"
            btnStyle="link"
            className="InfoWindow__Body__Content__Btn"
            onClick={() => openReportModal(Id)}
          >
            <i className="material-icons">bug_report</i>
            Reportar
          </Button>
        </Col>
      </Row>
    </Grid>
  )
}

const InfoWindow = props => {
  const {
    content,
    toggleInfoWindow,
    mobile,
    openReportModal,
    businessType,
  } = props
  const realty = { content, businessType }
  const classes = classNames({
    InfoWindow: !mobile,
    'InfoWindow--mobile': mobile,
  })
  return (
    <div className={classes}>
      <div className="InfoWindow__Body">
        <div className="InfoWindow__Header">
          <i className="material-icons" onClick={toggleInfoWindow}>
            close
          </i>
        </div>
        {realty && realty.content.Id ? (
          renderRealty(realty, openReportModal)
        ) : (
          <div className="InfoWindow__Loader">
            <Loader sizeUnit="px" size={50} color={COLORS.gray28} visible />
            Carregando
          </div>
        )}
      </div>
    </div>
  )
}

export default InfoWindow
