export const verifyBeforeSubmit = (values, onSubmit) => {
  if (values.minRooms && values.maxRooms) {
    if (values.minRooms > values.maxRooms) {
      let step = values.maxRooms
      values.maxRooms = values.minRooms
      values.minRooms = step
    }
  }

  if (values.minSuits && values.maxSuits) {
    if (values.minSuits > values.maxSuits) {
      let step = values.maxSuits
      values.maxSuits = values.minSuits
      values.minSuits = step
    }
  }

  if (values.minGarages && values.maxGarages) {
    if (values.minGarages > values.maxGarages) {
      let step = values.maxGarages
      values.maxGarages = values.minGarages
      values.minGarages = step
    }
  }

  if (values.minUsefulArea && values.maxUsefulArea) {
    if (values.minUsefulArea > values.maxUsefulArea) {
      let step = values.maxUsefulArea
      values.maxUsefulArea = values.minUsefulArea
      values.minUsefulArea = step
    }
  }

  if (values.minTotalArea && values.maxTotalArea) {
    if (values.minTotalArea > values.maxTotalArea) {
      let step = values.maxTotalArea
      values.maxTotalArea = values.minTotalArea
      values.minTotalArea = step
    }
  }
  onSubmit({ more: values })
}
