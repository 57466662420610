import { createStore, combineReducers, applyMiddleware } from 'redux'
import { composeWithDevTools } from 'redux-devtools-extension'
import Immutable from 'seamless-immutable'
import thunk from 'redux-thunk'

const types = {
  LOAD_PROPERTIES: '@properties/LOAD',
  SET_QUERY_FILTER: '@filter/SET_QUERY',
  SET_BUSINESS_TYPE_FILTER: '@filter/SET_BUSINESS_TYPE',
  SET_PRICE_RANGE_FILTER: '@filter/SET_PRICE_RANGE',
}

const searchFilterInitialState = Immutable({
  query: '',
  businessType: '',
  priceRange: [],
})

function searchFilters(state = searchFilterInitialState, action) {
  switch (action.type) {
    case types.SET_QUERY_FILTER:
      return state.merge({ query: action.payload })
    case types.SET_BUSINESS_TYPE_FILTER:
      return state.merge({ businessType: action.payload })
    case types.SET_PRICE_RANGE_FILTER:
      return state.merge({ priceRange: action.payload })
    default:
      return state
  }
}

const propertiesInitialState = Immutable({
  results: [],
})

function properties(state = propertiesInitialState, action) {
  switch (action.type) {
    case types.LOAD_PROPERTIES:
      return state.merge({ results: action.payload })
    default:
      return state
  }
}

const reducers = combineReducers({
  searchFilters,
  properties,
})

const configureStore = rootReducer => {
  let store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(thunk)),
  )

  return store
}

const store = configureStore(reducers)

export default store
