import React from 'react'

const PriceRangeInfo = () => (
  <p className="FilterPriceRange__Popover__Info">
    Escolha o valor mínimo e máximo dos imóveis.
    {/* nesta região, o valor médio é
    de R$ {((max - min) / 2).toLocaleString('pt-BR')}. */}
  </p>
)

export default PriceRangeInfo
