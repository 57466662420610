import React from 'react'
import classNames from 'classnames'
import Header from 'components/Header'
import { Grid, Row, Col } from 'components/Grid'

const PageBase = ({
  className,
  fluid,
  children,
  TopComponent,
  BottomComponent,
}) => {
  const classes = classNames('PageBase', className)

  return (
    <div className={classes}>
      <Header />
      {TopComponent && <TopComponent />}
      <Grid fluid={fluid} className="PageBase__Grid">
        <Row>
          <Col xs>{children}</Col>
        </Row>
      </Grid>
      {BottomComponent && <BottomComponent />}
    </div>
  )
}

export default PageBase
