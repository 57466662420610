import React, { PureComponent } from 'react'
import { Row } from 'components/Grid'
import { Button } from 'components/Buttons'
import PencilIcon from 'components/SvgIcons/PencilSvgIcon'
import './index.css'
import I18n from 'locales'
import classNames from 'classnames'

class DrawButton extends PureComponent {
  constructor() {
    super()
    this.state = {
      active: false,
    }
  }

  toggleContainer = () => {
    this.setState(state => ({ active: !state.active }))
  }

  componentWillMount() {
    document.addEventListener('mousedown', this.handleClick, false)
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClick, false)
  }

  handleClick = e => {
    if (this.node && this.node.contains(e.target)) {
      return
    }

    this.state.active && this.toggleContainer()
  }

  renderOption = (onClick, label) => (
    <Row between="xs">
      <Button onClick={onClick} btnStyle="link">
        {label}
      </Button>
    </Row>
  )

  draw = (drawMethod, close) => {
    return () => {
      drawMethod()
      close()
    }
  }

  render() {
    const { drawPolygon, drawFreeHand, up = false } = this.props
    const { active } = this.state
    const classes = classNames({
      FilterContainer: !up,
      'FilterContainer--up': up,
    })
    return (
      <div style={{ position: 'relative' }} data-tut="step-five">
        <Button
          data-tut="step-five"
          className="Search__MapButton"
          onClick={this.toggleContainer}
        >
          <PencilIcon className="DrawButton__Icon" />
          {I18n.t('components.drawButton.drawInMap')}
        </Button>
        {active && (
          <div className={classes} ref={el => (this.node = el)}>
            <Row between="xs">
              <span className="DrawButton__Instruction">
                {I18n.t('components.drawButton.instruction')}
              </span>
            </Row>
            {this.renderOption(
              this.draw(drawPolygon, this.toggleContainer),
              I18n.t('components.drawButton.polygon'),
            )}
            {this.renderOption(
              this.draw(drawFreeHand, this.toggleContainer),
              I18n.t('components.drawButton.handFree'),
            )}
          </div>
        )}
      </div>
    )
  }
}

export default DrawButton
