export const formatDocument = doc => {
  if (doc) {
    let docAsString = doc + ''
    if (docAsString.length > 11) {
      return docAsString.replace(
        /^(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
        '$1.$2.$3/$4-$5',
      )
    } else {
      return docAsString.replace(/^(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
    }
  }
  return ''
}
