import React from 'react'
import { authed } from 'services/auth'
import LoggedIn from './LoggedIn'
import LoggedOut from './LoggedOut'
import './index.css'

class MobileNavMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isOpen: false,
    }
  }

  render() {
    const { isOpen } = this.state
    const sidebarStatus = isOpen ? 'Open' : 'Close'
    const sidebarClass = `NavMenuMobile__Sidebar NavMenuMobile__Sidebar__${sidebarStatus}`
    const sideHeaderClass = `NavMenuMobile__SidebarHeader NavMenuMobile__SidebarHeader__${sidebarStatus}`
    return (
      <div className="NavMenuMobile">
        <div className="NavMenuMobile__MenuContainer">
          <i
            className="material-icons"
            onClick={() => this.setState({ isOpen: !isOpen })}
          >
            menu
          </i>
        </div>
        <div className={sideHeaderClass}>
          <div className="NavMenuMobile__MenuContainer__Close">
            <i
              className="material-icons"
              onClick={() => this.setState({ isOpen: !isOpen })}
            >
              close
            </i>
          </div>
        </div>
        <div className={sidebarClass}>
          {authed() ? <LoggedIn /> : <LoggedOut />}
        </div>
      </div>
    )
  }
}

export default MobileNavMenu
