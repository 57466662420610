import React from 'react'
import PropTypes from 'prop-types'
import { COLORS } from 'constants/colors'
import './index.css'

function MarkerPin(props) {
  const { fillColor, width, height, scale, onClick, className, eepin } = props
  return eepin ? (
    <div className="Marker__Eepin" />
  ) : (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={Math.round(width * scale)}
      height={Math.round(height * scale)}
      viewBox="0 0 24 24"
      onClick={onClick}
      className={className}
    >
      <path
        d="M12 2C8.13 2 5 5.13 5 9c0 5.25 7 13 7 13s7-7.75 7-13c0-3.87-3.13-7-7-7zm0 9.5c-1.38 0-2.5-1.12-2.5-2.5s1.12-2.5 2.5-2.5 2.5 1.12 2.5 2.5-1.12 2.5-2.5 2.5z"
        fill={fillColor}
      />
      <path d="M0 0h24v24H0z" fill="none" />
    </svg>
  )
}

MarkerPin.defaultProps = {
  fillColor: COLORS.gray28,
  scale: 0.6,
  width: 64,
  height: 64,
  className: 'Marker',
  eepin: false,
}

MarkerPin.propTypes = {
  scale: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  fillColor: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  eepin: PropTypes.bool,
}

export default MarkerPin
