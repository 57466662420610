export const getObjectValues = obj => {
  if (!obj) return
  let acc = ''
  const iterate = obj => {
    for (let property in obj) {
      if (obj.hasOwnProperty(property)) {
        if (typeof obj[property] === 'object') iterate(obj[property], acc)
        else if (obj[property]) acc += ' ' + obj[property]
      }
    }
  }
  iterate(obj)
  return acc
}
